import PubSub from 'pubsub-js';
import WebFont from 'webfontloader';
import { Responsive } from '../utils';

const COMPONENT_NAME = 'tcl-page';
const EVENT_KEY = COMPONENT_NAME;

export const PageEvents = {
  PAGE_READY: `${EVENT_KEY}.pageReady`,
};

/**
 * version    1.0
 * class      <type> (Page)
 * classdesc  Component representing the whole Page (down from html)
 */
export class Page {
  constructor() {
    this._isPageReady = false;
    this._findUIElements();
    this._bindEvents();
    this._initUI();
  }

  // private methods

  _findUIElements() {
    this._els = {
      html: $('html'),
      body: $('body'),
    };
  }

  _bindEvents() {
    // component events
    PubSub.subscribe(PageEvents.PAGE_READY, () => {
      this._isPageReady = true;
      this._els.body.addClass('tcl-page--is-ready');
    });
  }

  _initUI() {
    this._initTextColors();
    this._initButtons();

    // on webfont loaded
    WebFont.load({
      active: () => {
        PubSub.publish(PageEvents.PAGE_READY);
      },
      // Museo slab (https://fonts.adobe.com/fonts/museo-slab)
      // Nota: attualmente incluso tramite l'account cosentino.m2@2mlab.com,
      //  ma prima di andare live bisogna usare l'account Adobe del cliente.
      //  Spessori usati: 300, 700, 900.
      typekit: {
        id: 'yhc6mms',
      },
      google: {
        families: [
          // Work Sans: https://fonts.google.com/specimen/Work+Sans?query=work+sans#standard-styles
          'Work+Sans:ital,wght@0,400;0,500;0,700;1,400',
        ],
      },
    });
  }

  // set text color as specified via data attribute
  _initTextColors($container = $('body')) {
    $container.find('[data-text-color]').each(function () {
      $(this).css('color', $(this).data('text-color'));
    });
    if (Responsive.getCurrentBreakpointName() === 'xs') {
      $container.find('[data-text-color-mobile]').each(function () {
        $(this).css('color', $(this).data('text-color-mobile'));
      });
    }
  }

  _initButtons($container = $('body')) {
    $container.find('[data-btn-icon]').each(function () {
      const $btn = $(this);
      if ($btn.find('.svg-icon').length === 0) {
        const iconName = $btn.attr('data-btn-icon');
        const flip = $btn[0].hasAttribute('data-btn-icon-flip');
        const icon = `<svg role="img" class="svg-icon svg-icon--${iconName}" aria-hidden="true"><use xlink:href="#sprite-${iconName}"></use></svg>`;
        if (flip) {
          $btn.prepend(icon);
        } else {
          $btn.append(icon);
        }
      }
    });
  }

  // public methods

  isPageReady() {
    return this._isPageReady;
  }
}
