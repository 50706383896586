import './slide-nav.scss';

import enquire from 'enquire.js';
import { Responsive } from '../utils';

const COMPONENT_NAME = 'tcl-slide-nav';
// const EVENT_KEY = COMPONENT_NAME;
const BEM = {
  BLOCK: COMPONENT_NAME,
  ELEMENT_SLIDENAV_DESKTOP: 'tcl-slide-nav--desktop',
};

/**
 * version    1.0
 * class      <type> (SlideNav)
 * classdesc  Slide Nav: Menu with annidate links, overlay on mobile
 */
export class SlideNav {
  constructor() {
    this._mobileSlideNavInitialized = false;
    this._findUIElements();
    this._bindEvents();
  }

  // private methods

  _findUIElements() {
    this._els = {
      slideNavDesktop: $(`.${BEM.ELEMENT_SLIDENAV_DESKTOP}`),
    };
  }

  _bindEvents() {
    const self = this;
    enquire.register(`${Responsive.getBreakpointMaxMediaQuery('md')}`, () => {
      self.initMobileSlideNav();
    });
    enquire.register(`${Responsive.getBreakpointMinMediaQuery('lg')}`, () => {
      self.closeMobileSlideNav();
      self.hoverMenuDesktop();
    });
  }

  hoverMenuDesktop() {
    const self = this;
    self._els.slideNavDesktop.find('.link-list > li').on('mouseenter', function () {
      $(this).find('ul').addClass('show');
      $(this).children('.list-item').addClass('focus--mouse');
    }).on('mouseleave', function () {
      $(this).find('ul').removeClass('show');
      $(this).children('.list-item').removeClass('focus--mouse');
    });
  }

  initMobileSlideNav() {
    if (!this._mobileSlideNavInitialized) {
      this._mobileSlideNavInitialized = true;
      this._els.slideNavDesktop.after($('#tmplSlideNavMobile').text());
      // remove collapse to the child if present
      $('.tcl-slide-nav--mobile').find('.link-sublist').removeClass('collapse');
      $('.tcl-slide-nav--mobile').find('.link-list > li').on('click', function () {
        $('.list-item').removeClass('clicked');
        $(this).find('.list-item').addClass('clicked');
      });
    }
  }

  closeMobileSlideNav() {
    if (this._mobileSlideNavInitialized) {
      $('#modalLeft').modal('hide');
    }
  }
}
