import './section-navbar.scss';
import * as Hammer from 'hammerjs';

const COMPONENT_NAME = 'section-navbar';

const BEM = {
  BLOCK: COMPONENT_NAME,
  BODY_MODAL_OPEN: 'modal-bottom-open',
  MODAL: 'modal-bottom',
  FORM_MOBILE: 'mobile-filters-form',
  TAG_LIST: 'tag-list',
};

/**
 * version    1.0
 * class      <type> (SectionNavbar)
 * classdesc
 */
export class SectionNavbar {
  constructor() {
    const self = this;

    self._vars = {};
    self._vars.threshold = 100;
    self._vars.dragInitialized = false;
    self._vars.currentHammerManager = false;

    // find elements
    self._els = {};
    self._els.body = $('body');
    self._els.navbar = $(`.${BEM.BLOCK}`);
    self._els.modal = $(`.${BEM.MODAL}`);
    self._els.formDesktop = self._els.navbar.find('.form-inline');
    self._els.formMobile = self._els.modal.find(`.${BEM.FORM_MOBILE}`);
    self._els.currentModal = false;
    self._els.currentModalDialog = false;
    self._els.tagList = self._els.navbar.find(`.${BEM.TAG_LIST}`);

    self._initForms();
    self._initTagList();

    // bind events

    self._els.modal.on('show.bs.modal', () => {
      self._els.body.addClass(BEM.BODY_MODAL_OPEN);
    });
    self._els.modal.on('shown.bs.modal', function () {
      self._initDrag($(this));
    });
    self._els.modal.on('hidden.bs.modal', () => {
      self._els.body.removeClass(BEM.BODY_MODAL_OPEN);
      self._destroyDrag();
    });
  }

  _initDrag($currentModal) {
    const self = this;
    if (self._vars.dragInitialized) {
      return;
    }

    self._vars.dragInitialized = true;
    self._els.currentModal = $currentModal;
    self._els.currentModalDialog = self._els.currentModal.find('.modal-dialog');
    self._vars.currentHammerManager = new Hammer.Manager(self._els.currentModal.find('.modal-header')[0]);
    self._vars.currentHammerManager.add(new Hammer.Pan({ direction: Hammer.DIRECTION_VERTICAL }));
    self._vars.currentHammerManager.on('pan', (ev) => { self._onPan(ev); });
    self._vars.currentHammerManager.on('panend', (ev) => { self._onPanEnd(ev); });
  }

  _destroyDrag() {
    const self = this;
    self._vars.dragInitialized = false;
    self._els.currentModal = false;
    self._els.currentModalDialog = false;
    self._vars.currentHammerManager.off('pan');
    self._vars.currentHammerManager.off('panend');
    self._vars.currentHammerManager = false;
  }

  _onPan(ev) {
    const self = this;
    const transforms = [];

    // Adjusting the current pinch/pan event properties using the previous ones set when they finished touching
    const newDeltaY = self._getBoundedDelta(ev);

    // Concatinating and applying transformations.
    transforms.push(`translate(0, ${newDeltaY}px`);
    self._els.currentModalDialog.css({
      transition: 'none',
      transform: transforms.join(' '),
    });
  }

  _onPanEnd(ev) {
    const self = this;
    if (ev.deltaY > self._vars.threshold) {
      self._els.currentModalDialog.css({
        transition: '',
        transform: '',
      });
      self._els.modal.modal('hide');
    } else {
      self._els.currentModalDialog.css({
        transition: '',
        transform: '',
      });
    }
  }

  // ritorna il nuovo delta limitandolo alle dimensioni della modale
  _getBoundedDelta(ev) {
    let newDeltaY = ev.deltaY;
    if (newDeltaY < 0) {
      newDeltaY = 0;
    }
    return newDeltaY;
  }

  _initForms() {
    const self = this;

    self._els.formDesktop.filter('[data-reload-on-change="true"]').each(function () {
      const $form = $(this);
      // self._setInputsValueFromUrl();

      $form.find('select:not([multiple="true"]), input').on('change', () => {
        self._formRedirect($form.serializeArray());
      });

      $form.find('select[multiple="true"]').on('show.bs.select', function () {
        $(this).data('value-when-shown', $(this).val());
      });

      $form.find('select[multiple="true"]').on('hidden.bs.select', function () {
        if (JSON.stringify($(this).data('value-when-shown')) !== JSON.stringify($(this).val())) {
          self._formRedirect($form.serializeArray());
        }
      });
    });

    self._els.formMobile.filter('[data-reload-on-change="true"]').each(function () {
      const $form = $(this);
      $form.on('submit', (e) => {
        e.preventDefault();
        self._formRedirect($form.serializeArray());
        return false;
      });
    });

    $(`.${BEM.BLOCK}, .${BEM.FORM_MOBILE}`).find('.chip button').on('click', function () {
      const $removeButton = $(this);
      const selectId = $removeButton.attr('data-select-id');
      const selectValue = $removeButton.attr('data-select-value');
      const $select = $(`#${selectId}`);
      $removeButton.closest('.chip').remove();
      $select.find(':selected').each(function () {
        if ($(this).val() === selectValue) {
          $select.find(`option[value="${selectValue}"]`).prop('selected', false);
        }
      });
      self._formRedirect($select.closest('form').serializeArray());
    });
  }

  // _setInputsValueFromUrl() {
  //   const searchParams = window.location.search.substring(1).split('&');

  //   if (searchParams.length >= 1) {
  //     searchParams.forEach((searchVal) => {
  //       const searchValName = searchVal.split('=')[0];
  //       const searchValValue = searchVal.split('=')[1];

  //       if (searchValValue && searchValValue.length > 0 && searchValValue.indexOf('%2C') !== -1) {
  //         const splittedValues = searchValValue.split('%2C');
  //         splittedValues.forEach((singleVal) => {
  //           $(`[name=${searchValName}]`).find(`[value=${singleVal}]`).prop('selected', true);
  //         });
  //       } else {
  //         $(`[name=${searchValName}]`).find(`[value=${searchValValue}]`).prop('selected', true);
  //       }
  //     });
  //   }
  // }

  _formRedirect(formValuesArray) {
    const self = this;
    const currentUrl = new URL(window.location.href);
    const formGroupedValues = self._groupBy(formValuesArray, 'name');
    const searchParams = new URLSearchParams();

    if (currentUrl.searchParams.has('search')) {
      searchParams.set('search', currentUrl.searchParams.get('search'));
    }

    Object.keys(formGroupedValues).forEach((name) => {
      const group = formGroupedValues[name];
      const groupName = formGroupedValues[name][0].name;
      const groupValue = formGroupedValues[name][0].value;
      let myUrl;
      if (groupValue && groupValue.length > 0) {
        for (let i = 0; i < group.length; i += 1) {
          const item = group[i];
          if (i < group.length - 1) {
            myUrl = myUrl ? `${myUrl + item.value},` : `${item.value},`;
          } else {
            myUrl = myUrl ? myUrl + item.value : item.value;
          }
        }

        searchParams.set(groupName, myUrl);
      }
    });

    window.location.search = searchParams.toString();
  }

  _groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      const ret = rv;
      (ret[x[key]] = ret[x[key]] || []).push(x);
      return ret;
    }, {});
  }

  _initTagList() {
    const self = this;
    const $selectedTag = self._els.tagList.find('.btn-secondary');
    if ($selectedTag.length > 0) {
      self._els.navbar.scrollLeft($selectedTag.offset().left + 32);
    }
  }
}
