import enquire from 'enquire.js';
import { Responsive } from '../utils';

import './header.scss';
import '../libs/jquery-accessibleMegaMenu.custom';

const COMPONENT_NAME = 'tcl-header';

const BEM = {
  BLOCK: COMPONENT_NAME,
  ELEMENT_TOGGLER: 'tcl-header__actions__item--toggler',
  ELEMENT_MAIN_NAV: 'tcl-header__nav',
  ELEMENT_PRE: 'tcl-header__pre',
  ELEMENT_PRE_NAV: 'tcl-header__pre__nav',
  BODY_STATE_OPEN: 'header-menu-open',
};

/**
 * version    1.0
 * class      <type> (Header)
 * classdesc  Component that manage the page header
 */
export class Header {
  constructor() {
    this._menuInitialized = false;

    this._els = {
      body: $('body'),
      header: $(`.${BEM.BLOCK}`),
      toggler: $(`.${BEM.ELEMENT_TOGGLER}`),
      mainNav: $(`.${BEM.ELEMENT_MAIN_NAV}`),
      preNav: $(`.${BEM.ELEMENT_PRE_NAV}`),
      preContainer: $(`.${BEM.ELEMENT_PRE} .container`),
    };

    if (this._els.header.length > 0) {
      this._bindEvents();
    }
  }

  // private methods

  _bindEvents() {
    const self = this;

    self._els.toggler.on('click', () => this.toggleMenu());

    enquire.register(`${Responsive.getBreakpointMaxMediaQuery('lg')}`, () => {
      self._relocatePreNav(true);
      self._destroyMainMenu();
    });

    enquire.register(`${Responsive.getBreakpointMinMediaQuery('xl')}`, () => {
      self._relocatePreNav(false);
      self._destroyMainMenu();
      self._initMainMenu();
    });
  }

  _relocatePreNav(mobileMode) {
    if (mobileMode) {
      this._els.mainNav.after(this._els.preNav);
    } else {
      this._els.preContainer.append(this._els.preNav);
    }
  }

  _destroyMainMenu() {
    this._menuInitialized = false;
    // this._els.mainNav.accessibleMegaMenu('destroy');
  }

  _initMainMenu() {
    if (this._menuInitialized) {
      return;
    }

    this._menuInitialized = true;

    const isDesktopMode = Responsive.getCurrentBreakpointName() === 'xl';

    const menuOptions = {
      /* prefix for generated unique id attributes, which are required
        to indicate aria-owns, aria-controls and aria-labelledby */
      uuidPrefix: 'accessible-megamenu',

      /* css class used to define the megamenu styling */
      menuClass: 'nav-menu',

      /* css class for a top-level navigation item in the megamenu */
      topNavItemClass: 'nav-item',

      /* css class for a megamenu panel */
      panelClass: 'sub-nav',

      /* css class for a group of items within a megamenu panel */
      panelGroupClass: 'sub-nav-group',

      /* css class for the hover state */
      hoverClass: 'hover',

      /* css class for the focus state */
      focusClass: 'focus',

      /* css class for the open state */
      openClass: 'open',

      /* css class for the sub nav open state */
      navOpenClass: 'tcl-header__nav--nav-open',
    };
    if (isDesktopMode) {
      menuOptions.openDelay = 0; // default open delay when opening menu via mouseover
      menuOptions.closeDelay = 250; // default open delay when opening menu via mouseover
      menuOptions.openOnMouseover = true;
    }

    this._els.mainNav.accessibleMegaMenu(menuOptions);
  }

  // public methods

  isMenuOpen() {
    return this._els.body.hasClass(BEM.BODY_STATE_OPEN);
  }

  toggleMenu() {
    if (!this.isMenuOpen()) {
      this._els.body.addClass(BEM.BODY_STATE_OPEN);
      this._initMainMenu();
    } else {
      this._els.body.removeClass(BEM.BODY_STATE_OPEN);
    }
  }
}
