import './table.scss';

import enquire from 'enquire.js';
import { Responsive } from '../utils';

const COMPONENT_NAME = 'table';
// const EVENT_KEY = COMPONENT_NAME;
const BEM = {
  BLOCK: COMPONENT_NAME,
};

/**
 * version    1.0
 * class      <type> (Table)
 * classdesc
 */
export class Table {
  constructor() {
    this._findUIElements();
    this._bindEvents();
  }

  // private methods

  _findUIElements() {
    this._els = {
      tbody: $(`.${BEM.BLOCK}`).find('tbody'),
      thead: $(`.${BEM.BLOCK}`).find('thead'),
    };
  }

  _bindEvents() {
    const self = this;

    self.setClasses();
    self._findUIElements();
    self.setBackgroundColor();

    enquire.register(`${Responsive.getBreakpointMaxMediaQuery('xs')}`, () => {
      self.setWidthMobile();
      self.setOverflowTabs();
      self.setPagination();
      self.changePaginationOnScroll();
    });
  }

  setClasses() {
    document.getElementsByTagName('table').forEach((table) => {
      table.classList.add('tcl-table', 'table', 'table-striped');
      table.removeAttribute('border');
    });
  }

  setBackgroundColor() {
    const self = this;
    let counter = 0;

    self._els.tbody.find('tr').each(function () {
      if (counter % 2 === 0 && counter !== 0 && !$(this).prev().hasClass('striped-row') && !$(this).prev().prev().hasClass('striped-row')) {
        $(this).addClass('striped-row');
        $(this).next().addClass('striped-row');
      }

      counter += 1;
    });
  }

  setWidthMobile() {
    const self = this;
    const tableWidth = self._els.thead.closest('.table-responsive').innerWidth();
    const firstTabWidth = self._els.thead.find('th:first-child').outerWidth();
    const secondTabWidth = tableWidth - firstTabWidth;
    const secondCols = self._els.tbody.find('td[rowspan="2"]').siblings();
    const secondColsInSecondRow = self._els.tbody.find('td[rowspan="2"]').closest('tr').next().find('td');

    self._els.thead.find('th:not(:first-child)').css('min-width', secondTabWidth + 2);

    secondCols.css({
      width: secondTabWidth + 2,
      'min-width': secondTabWidth + 2,
    });

    secondColsInSecondRow.css({
      width: secondTabWidth + 2,
      'min-width': secondTabWidth + 2,
    });
  }

  setOverflowTabs() {
    const self = this;
    const firstTab = self._els.thead.find('th:first-child');
    const otherTabs = self._els.thead.find('th:not(:first-child)');
    const firstTabWidth = '129.94px';
    const firstCols = self._els.tbody.find('td[rowspan="2"]');
    const secondColsInSecondRow = self._els.tbody.find('td[rowspan="2"]').closest('tr').next().find('td');
    const footerTable = self._els.tbody.find('.table__footer-pagination td:first-child');
    const footerTableSecondCol = self._els.tbody.find('.table__footer-pagination td:nth-child(2)');

    firstTab.css({
      position: 'absolute',
      left: '15px',
      'z-index': 9,
      width: firstTabWidth,
    });

    otherTabs.css({
      position: 'relative',
      left: firstTabWidth,
    });

    footerTable.css({
      width: firstTabWidth,
    });

    footerTableSecondCol.css({
      width: `calc(100% - ${firstTabWidth})`,
    });

    firstCols.siblings().css({
      position: 'relative',
      left: firstTabWidth,
    });

    secondColsInSecondRow.siblings().css({
      position: 'relative',
      left: firstTabWidth,
    });

    firstCols.each(function () {
      $(this).css({
        position: 'absolute',
        'z-index': 9,
        width: firstTabWidth,
      });
    });

    firstCols.each(function () {
      const heightRowSpanRow1 = $(this).next().innerHeight() + 25;
      const heightRowSpanRow2 = $(this).closest('tr').next().find('td:first-child')
        .innerHeight() + 25;

      $(this).css({
        height: heightRowSpanRow1 + heightRowSpanRow2,
      });
    });
  }

  setPagination() {
    const self = this;
    const tablePage = self._els.tbody.find('.table__page');
    const numberOfPages = self._els.thead.find('th:not(:first-child)');

    numberOfPages.each(() => {
      tablePage.append('<span class="page-dot" />');
    });

    tablePage.find('span:first-child').addClass('active');
  }

  changePaginationOnScroll() {
    const self = this;
    const tabWidth = Math.floor(self._els.thead.find('th:nth-child(2)').innerWidth());
    let currentTab = 1;
    let lastScrollTab = 0;
    let lastScrollLeft = 0;

    $('.table-responsive').on('scroll', () => {
      const documentScrollLeft = $('.table-responsive').scrollLeft();

      if (lastScrollLeft !== documentScrollLeft) {
        lastScrollLeft = documentScrollLeft;

        if (lastScrollLeft >= (tabWidth * currentTab - 50) && lastScrollLeft > lastScrollTab) {
          lastScrollTab = lastScrollLeft;
          currentTab += 1;
          self.setActiveTab(currentTab);
        }

        if (lastScrollLeft <= ((tabWidth * (currentTab - 2)) + 50) && lastScrollLeft < lastScrollTab) {
          lastScrollTab = lastScrollLeft;
          currentTab -= 1;
          self.setActiveTab(currentTab);
        }
      }
    });
  }

  setActiveTab(activeTab) {
    const self = this;
    const lastActiveTab = self._els.tbody.find('.page-dot.active');
    const newActiveTab = self._els.tbody.find(`.page-dot:nth-child(${activeTab})`);

    lastActiveTab.removeClass('active');
    newActiveTab.addClass('active');
  }
}
