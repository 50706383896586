import './modal-newsletter.scss';

const BEM = {
  BODY_MODAL_OPEN: 'modal-open--newsletter',
  FOOTER_FORM: 'modal-newsletter-form',
};

/**
 * version    1.0
 * class      <type> (ModalNewsletter)
 * classdesc  Modale Newsletter che si apre al click sul button iscriviti presente nel prefooter (box azzurro con cta arancione)
 */
export class ModalNewsletter {
  constructor() {
    const $body = $('body');
    const $modal = $('#modalNewsletter');

    $modal.on('show.bs.modal', () => {
      $body.addClass(BEM.BODY_MODAL_OPEN);
    });

    $modal.on('hidden.bs.modal', () => {
      $body.removeClass(BEM.BODY_MODAL_OPEN);
    });

    $modal.on('shown.bs.modal', () => {
      const self = this;

      self._findUIElements();
      self._submitForm();

      setTimeout(() => {
        $('#inputName').trigger('focus');
      }, 300);
    });
  }

  _findUIElements() {
    this._els = {
      form: $(`.${BEM.FOOTER_FORM}`),
    };
  }

  _submitForm() {
    const self = this;
    let nomeVal;
    let cognomeVal;
    let indirizzoemailVal;
    let telefonoVal;

    self._els.form.find('button').on('click', function (event) {
      if (this.closest('form').checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        nomeVal = self._els.form.find('input[name="nome"]').val();
        cognomeVal = self._els.form.find('input[name="cognome"]').val();
        indirizzoemailVal = self._els.form.find('input[name="indirizzoemail"]').val();
        telefonoVal = self._els.form.find('input[name="telefono"]').val();
        // can't resolve CORS server side so we do call without ajax
        $(`<form action="https://s363915122.t.eloqua.com/e/f2" method="POST"><input type="hidden" name="elqFormName" value="MLPS_NL-1639060336045"><input type="hidden" name="elqSiteId" value="363915122"><input type="hidden" name="elqCampaignId"><input type="hidden" name="nome" value="${nomeVal}"><input type="hidden" name="cognome" value="${cognomeVal}"><input type="hidden" name="indirizzoemail" value="${indirizzoemailVal}"><input type="hidden" name="telefono" value="${telefonoVal}"></form>`).appendTo('body').trigger('submit');
      }
      this.closest('form').classList.add('was-validated');
    });
  }
}
