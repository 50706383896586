import './article-full-text.scss';
import fitvids from 'fitvids';

const COMPONENT_NAME = 'tcl-article-full-text';
const BEM = {
  BLOCK: COMPONENT_NAME,
};

/**
 * version    1.0
 * class      <type> (ArticleFullText)
 * classdesc  Article Full Text: plain text that can contain ul, link, images (big or small), embedded video
 */
export class ArticleFullText {
  constructor() {
    this._initResponsiveVideo();
  }

  // private methods

  _initResponsiveVideo() {
    fitvids(`.${BEM.BLOCK}`);
  }
}
