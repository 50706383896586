import JSCookie from 'js-cookie';

/** This class contains common methods used to maniupulate cookies.
 *
 * @class
 * @name Cookies
 */
export class Cookies {
  /**
   * Gets a specific cookie value.
   * @param {String} name - the name of the cookie.
   */
  static get(name) {
    return JSCookie.get(name);
  }

  /**
   * Sets a cookie value.
   * @param {String} name - the name of the cookie.
   * @param {Object} value - the value of the cookie.
   * @param {Integer} expires - days within the cookie expires.
   * @param {String} path - path of the cookie.
   */
  static set(name, value, expires = 2, path = '/') {
    JSCookie.set(name, value, { expires: Number.parseInt(expires, 10), path });
  }

  /**
   * Removes a cookie for a specific path
   * @param {String} name - name of the cookie
   * @param {String} path - path of the cookie
   */
  static remove(name, path = '/') {
    JSCookie.remove(name, { path });
  }
}
