/** This class collect and returns common page data, such as the storeId, langId and catalogId.
 *
 * @class
 * @name PageData
 */
export class PageData {
  /**
   * Return the lang of the current page, specified in the html lang attribute.
   */
  static getLang() {
    return $('html').attr('lang');
  }
}
