import './external-link.scss';

const COMPONENT_NAME = 'tcl-external-link';
// const EVENT_KEY = COMPONENT_NAME;
const BEM = {
  BLOCK: COMPONENT_NAME,
  SINGLE_COL: 'tcl-external-link__col',
};

/**
 * version    1.0
 * class      <type> (ExternalLink)
 * classdesc  External Link: contains one title, subtitle and external link list
 */
export class ExternalLink {
  constructor() {
    this._findUIElements();
    this._bindEvents();
  }

  // private methods

  _findUIElements() {
    this._els = {
      externalLink: $(`.${BEM.BLOCK}`),
      singleCol: $(`.${BEM.SINGLE_COL}`),
    };
  }

  _bindEvents() {
    const self = this;
    self._els.singleCol.each(function () {
      $(this).on('click', () => self._triggerCtaClick($(this)));
    });
  }

  _triggerCtaClick($singleColClicked) {
    $singleColClicked.find('a')[0].click();
  }
}
