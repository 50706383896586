import './highlight-article.scss';

const COMPONENT_NAME = 'tcl-highlight-article';

const BEM = {
  BLOCK: COMPONENT_NAME,
  TITLE: 'tcl-highlight-article__title',
  CTAS: 'tcl-highlight-article__cta',
  OVERLAY: 'tcl-highlight-article__overlay',
  MODIFIER_HOVER: 'tcl-highlight-article--hover',
};

/**
 * version    1.0
 * class      <type> (HighlightArticle)
 * classdesc  Highlight Article: contains occhiello, title, abstract and cta
 */
export class HighlightArticle {
  constructor() {
    this._findUIElements();
    this._generateAnchorOverlay();
  }

  // private methods

  _findUIElements() {
    this._els = {
      items: $(`.${BEM.BLOCK}`),
    };
  }

  _generateAnchorOverlay() {
    this._els.items.each(function () {
      const $item = $(this);
      const $title = $item.find(`.${BEM.TITLE}`);
      const $ctaLinks = $item.find(`.${BEM.CTAS} a`);
      if ($ctaLinks.length === 1) {
        const $overlay = $(`<a class="${BEM.OVERLAY}" href="${$ctaLinks.first().attr('href')}">
          <span class="sr-only">
            ${$ctaLinks.first().html()}${$title.length > 0 && ` - ${$title.text()}`}
          </span>
        </a>`);
        $overlay.hover(() => $item.addClass(BEM.MODIFIER_HOVER), () => $item.removeClass(BEM.MODIFIER_HOVER));
        $item.append($overlay);
      }
    });
  }
}
