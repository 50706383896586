export const readTime = (str) => {
  let text = str;

  // try to remove line break
  text = text.replace(/(\r\n|\n|\r)/gm, '');
  // remove start e end spaces
  text = text.replace(/(^\s*)|(\s*$)/gi, '');
  // transform double spaces into single space
  text = text.replace(/[ ]{2,}/gi, ' ');

  // assume reading 150 word in a minute
  return Math.ceil(text.split(' ').length / 150);
};
