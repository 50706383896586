import './preview-item.scss';

const COMPONENT_NAME = 'preview';

const BEM = {
  BLOCK: COMPONENT_NAME,
  TITLE: 'preview__title',
  ATTACHMENTS: 'preview__attachments',
  CTAS: 'preview__ctas',
  ANCHOR_OVERLAY: 'preview__anchor-overlay',
  MODIFIER_HOVER: 'preview--anchor-hover',
};

/**
 * version    1.0
 * class      <type> (PreviewItem)
 * classdesc  Preview Item: anteprima contenuto da usare nelle liste
 */
export class PreviewItem {
  constructor() {
    this._findUIElements();
    this._generateAnchorOverlay();
  }

  // private methods

  _findUIElements() {
    this._els = {
      items: $(`.${BEM.BLOCK}`),
    };
  }

  _generateAnchorOverlay() {
    this._els.items.each(function () {
      const $item = $(this);
      const $title = $item.find(`.${BEM.TITLE}`);
      const $attachmentLinks = $item.find(`.${BEM.ATTACHMENTS} a`);
      const $ctaLinks = $item.find(`.${BEM.CTAS} a`);
      if ($attachmentLinks.length === 0 && $ctaLinks.length === 1) {
        const $overlay = $(`<a class="${BEM.ANCHOR_OVERLAY}" href="${$ctaLinks.first().attr('href')}">
          <span class="sr-only">
            ${$ctaLinks.first().html()}${$title.length > 0 && ` - ${$title.html()}`}
          </span>
        </a>`);
        $overlay.hover(() => $item.addClass(BEM.MODIFIER_HOVER), () => $item.removeClass(BEM.MODIFIER_HOVER));
        $item.append($overlay);
      }
    });
  }
}
