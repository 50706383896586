import './footer.scss';

import enquire from 'enquire.js';
import { Responsive } from '../utils';

const COMPONENT_NAME = 'tcl-footer';
// const EVENT_KEY = COMPONENT_NAME;
const BEM = {
  BLOCK: COMPONENT_NAME,
  ELEMENT_FOOTER_MOBILE: 'tcl-footer--mobile',
};

/**
 * version    1.0
 * class      <type> (Footer)
 * classdesc  Main footer
 */
export class Footer {
  constructor() {
    this._desktopFooterInitialized = false;
    this._findUIElements();
    this._bindEvents();
  }

  // private methods

  _findUIElements() {
    this._els = {
      footerMobile: $(`.${BEM.ELEMENT_FOOTER_MOBILE}`),
    };
  }

  _bindEvents() {
    const self = this;
    enquire.register(`${Responsive.getBreakpointMinMediaQuery('md')}`, () => {
      self.initDesktopFooter();
    });
  }

  initDesktopFooter() {
    if (!this._desktopFooterInitialized) {
      this._desktopFooterInitialized = true;
      this._els.footerMobile.after($('#tmplFooterDesktop').text());
    }
  }
}
