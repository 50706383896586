import enquire from 'enquire.js';
import { Logger } from './logger';

const BreakException = {};

let instance = null;

/**
 * Gives information about the breakpoint widths used by the project,
 * and provide some utility method to build min/max-width media query string,
 * intended to be used together with enquire.js.
 * @class
 */
class _Responsive {
  constructor() {
    const self = this;

    // implement singleton design pattern
    if (instance) {
      return instance;
    }
    instance = this;

    /**
     * List of breakpoint min-width values, implicitly specified in pixels
     * (see values specified by $grid-breakpoints in \node_modules\bootstrap\scss\_variables.scss).
     * Important: the list must be in descending order.
     */
    this.Breakpoints = {
      xl: 1280,
      lg: 992,
      md: 768,
      sm: 576,
      xs: 0,
    };

    this._currentBreakpointName = 'xs';

    Object.keys(this.Breakpoints).forEach((key) => {
      enquire.register(`${self.getBreakpointMinMediaQuery(key)} and ${self.getBreakpointMaxMediaQuery(key)}`, () => {
        self._currentBreakpointName = key;
        Logger.log(`Responsive breakpoint changed: ${self._currentBreakpointName}`);
      });
    });
  }

  /**
   * Returns the current breakpoint name, among those listed inside Breakpoints.
   */
  getCurrentBreakpointName() {
    return this._currentBreakpointName;
  }

  /**
   * Returns the given breakpoint min width numeric value (no 'px' subfix).
   * @param {String} breakpointName
   */
  getBreakpointMinWidth(breakpointName) {
    return this.Breakpoints[breakpointName];
  }

  /**
   * Returns the given breakpoint max width numeric value (no 'px' subfix).
   * @param {String} breakpointName
   */
  getBreakpointMaxWidth(breakpointName) {
    const self = this;
    let returnValue = 100000;
    let prevBreakpointName = null;
    try {
      Object.keys(this.Breakpoints).forEach((key) => {
        if (key === breakpointName) {
          if (prevBreakpointName === null) {
            throw BreakException;
          } else {
            returnValue = self.Breakpoints[prevBreakpointName] - 1;
            throw BreakException;
          }
        }
        prevBreakpointName = key;
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
    return returnValue;
  }

  /**
   * Returns the given breakpoint max width media query string.
   * Eg: breakpointName: "md" ---> "(min-width: 960px)"
   *
   * @param {String} breakpointName
   */
  getBreakpointMinMediaQuery(breakpointName) {
    return `(min-width: ${this.getBreakpointMinWidth(breakpointName)}px)`;
  }

  /**
   * Returns the given breakpoint max width media query string.
   * Eg: breakpointName: "md" ---> (max-width: 1599px)"
   *
   * @param {String} breakpointName
   */
  getBreakpointMaxMediaQuery(breakpointName) {
    return `(max-width: ${this.getBreakpointMaxWidth(breakpointName)}px)`;
  }

  /**
   * Return a boolean telling if the current resolution match the given media query or not.
   * (It simply wrap window.matchMedia()
   */
  /* eslint class-methods-use-this: 0 */
  matchBreakpoint(mediaQuery) {
    return window.matchMedia(mediaQuery).matches;
  }
}

export const Responsive = new _Responsive();
