window.lazySizesConfig = window.lazySizesConfig || {};

// in case you want to use custom media query aliases in your markup, instead of full media queries
window.lazySizesConfig.customMedia = {
  '--xs': '(max-width: 575px)',
  '--sm': '(max-width: 767px)',
  '--md': '(max-width: 991px)',
  '--lg': '(max-width: 1199px)',
  '--xl': '(max-width: 10000px)',
};
