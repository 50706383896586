/** asyncSVG

  Author: Marcello Cosentino
  Reference: https://css-tricks.com/ajaxing-svg-sprite/

  asyncSVG looks for link tags with rel="async-svg", load them asynchronously
  and inject them inside the body (hidden).
  This way single SVG icons can be used as showed in the example below:

    <head>
      ...
      <link href="/static/images/svg/spritemap.svg" rel="async-svg">
    </head>
    <body>
      ...
      <svg role="img" class="svg-icon svg-icon--phone" title="Phone icon">
        <use xlink:href="#phone"></use>
      </svg>
    </body>

  The advantages of this approach are:
  - svg sprite file will be cached
  - use href is not external and thus css properties can be inherited from the page
*/

import $ from 'jquery';
import svg4everybody from 'svg4everybody';

export function asyncSVG() {
  $('link[rel="async-svg"]').each(function () {
    const svgUrl = $(this).attr('href');
    $.get(svgUrl, (data) => {
      const $asyncSvg = $('<div id="asyncSvg" aria-hidden="true" style="position:absolute;top:0;visibility:hidden;height:0;width:0;"></div>');
      $asyncSvg[0].innerHTML = new XMLSerializer().serializeToString(data.documentElement);
      $('body').prepend($asyncSvg);
      // force support to SVG External Content for old browsers (eg: IE11)
      svg4everybody();
    });
  });
}
