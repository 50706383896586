import './article-heading.scss';
import { readTime } from '../utils/readTime';

const COMPONENT_NAME = 'tcl-article-heading';
// const EVENT_KEY = COMPONENT_NAME;
const BEM = {
  BLOCK: COMPONENT_NAME,
  READ_TIME: 'tcl-article-heading__read-timing__val',
};

/**
 * version    1.0
 * class      <type> (ArticleHeading)
 * classdesc  Article Heading: breadcrumb, badge, title, subtitle, data, tempo di lettura img (desktop/mobile), share component
 */
export class ArticleHeading {
  constructor() {
    this._findUIElements();
    this._setReadTime();
  }

  // private methods

  _findUIElements() {
    this._els = {
      articleHeading: $(`.${BEM.BLOCK}`),
      articleHeadingReadTime: $(`.${BEM.READ_TIME}`),
      articleFullText: $(`.${BEM.BLOCK}`).siblings('.tcl-article-full-text').find('.tcl-article-full-text__col').text(),
    };
  }

  _setReadTime() {
    const self = this;
    const readTimeArticle = readTime(this._els.articleFullText);

    self._els.articleHeadingReadTime.text(readTimeArticle);
  }
}
