import '../stylesheets/styles.scss';

import 'core-js';

import 'bootstrap-italia';

import './utils/imagesConfig';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';

import 'slick-carousel';

import { PageData, Cookies, Keyboard, Logger, Responsive } from './utils'; // eslint-disable-line object-curly-newline
import { asyncSVG } from './libs/asyncSVG';
import { ModalSearch } from './components/modal-search';
import { ModalNewsletter } from './components/modal-newsletter';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { SectionHead } from './components/section-head';
import { MainCarousel } from './components/main-carousel';
import { Page } from './components/page';
import { ContextHeading } from './components/context-heading';
import { BannerService } from './components/banner-service';
import { PrevNextNav } from './components/prev-next-nav';
import { PreviewItem } from './components/preview-item';
import { PreFooter } from './components/pre-footer';
import { RelatedSource } from './components/related-source';
import { GrayBox } from './components/gray-box';
import { ListHead } from './components/list-head';
import { ItemList } from './components/item-list';
import { ExternalLink } from './components/external-link';
import { BannerReel } from './components/banner-reel';
import { HighlightArticle } from './components/highlight-article';
import { Breadcrumb } from './components/breadcrumb';
import { SearchHeading } from './components/search-heading';
import { SectionNavbar } from './components/section-navbar';
import { Sharer } from './components/sharer';
import { ArticleFullText } from './components/article-full-text';
import { ArticleHeading } from './components/article-heading';
import { SlideNav } from './components/slide-nav';
import { Sondaggio } from './components/sondaggio';
import { BadgeList } from './components/badge-list';
import { Table } from './components/table';
import { Sondaggi } from './components/sondaggi';
import { BannerSite } from './components/banner-site';

if (!('object-fit' in document.createElement('a').style)) {
  require('lazysizes/plugins/object-fit/ls.object-fit'); // eslint-disable-line global-require
}

// load asyncronoysly the SVG sprite and inject it inside the page
asyncSVG();

// initialize components
const Components = {
  modalSearch: new ModalSearch(),
  modalNewsletter: new ModalNewsletter(),
  header: new Header(),
  footer: new Footer(),
  sectionHead: new SectionHead(),
  mainCarousel: new MainCarousel(),
  contextHeading: new ContextHeading(),
  bannerService: new BannerService(),
  prevNextNav: new PrevNextNav(),
  previewItem: new PreviewItem(),
  preFooter: new PreFooter(),
  relatedSource: new RelatedSource(),
  grayBox: new GrayBox(),
  sondaggi: new Sondaggi(),
  listHead: new ListHead(),
  itemList: new ItemList(),
  externalLink: new ExternalLink(),
  bannerReel: new BannerReel(),
  highlightArticle: new HighlightArticle(),
  breadcrumb: new Breadcrumb(),
  searchHeading: new SearchHeading(),
  sectionNavbar: new SectionNavbar(),
  sharer: new Sharer(),
  articleFullText: new ArticleFullText(),
  articleHeading: new ArticleHeading(),
  slideNav: new SlideNav(),
  sondaggio: new Sondaggio(),
  badgeList: new BadgeList(),
  table: new Table(),
  bannerSite: new BannerSite(),
};

const PageInstance = new Page(); // must be initialized after the other components

export {
  PageInstance,
  Components,
  PageData,
  Cookies,
  Keyboard,
  Logger,
  Responsive,
};
