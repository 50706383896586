import './main-carousel.scss';

import PubSub from 'pubsub-js';
import enquire from 'enquire.js';
import { PageEvents } from './page';
import { Responsive } from '../utils';
import { SliderConfig } from '../utils/sliderConfig';

const COMPONENT_NAME = 'tcl-main-carousel';
// const EVENT_KEY = COMPONENT_NAME;
const BEM = {
  BLOCK: COMPONENT_NAME,
  ELEMENT_SLIDER: 'tcl-main-carousel__slider',
  ELEMENT_SLIDER_ITEM: 'tcl-main-carousel__slider__item',
  ELEMENT_SLIDE: 'tcl-main-carousel__slide',
  ELEMENT_FOOT: 'tcl-main-carousel__foot',
};

/**
 * version    1.0
 * class      <type> (MainCarousel)
 * classdesc  Main Carousel: contains one or multiple slides, each having an optional __background (image or video) and a __content texts.
 *            If contains multiple slides a slider will be shown.
 */
export class MainCarousel {
  constructor($container = $('body')) {
    const self = this;

    // find elements
    this._els = {};
    this._els.container = $container;
    this._els.mainCarousel = this._els.container.find(`.${BEM.BLOCK}`);

    // init UI
    PubSub.subscribe(PageEvents.PAGE_READY, () => {
      this._els.mainCarousel.each(function () {
        self._initSingleInstance($(this));
      });
    });
  }

  // private methods

  _applyMobileAdjustments($instanceEl) {
    const self = this;
    let higherSlideHeight = 0;

    $instanceEl.find(`.${BEM.ELEMENT_SLIDE}`).each(function () {
      const $slide = $(this);
      higherSlideHeight = $slide.find('.container').height() > higherSlideHeight ? $slide.find('.container').height() : higherSlideHeight;
    });

    self._setHeightToAllSlide($instanceEl, higherSlideHeight);
    self._moveDots($instanceEl, higherSlideHeight);
  }

  _setHeightToAllSlide($instanceEl, higherSlideHeight) {
    $instanceEl.find(`.${BEM.ELEMENT_SLIDE}`).each(function () {
      const $slide = $(this);
      $slide.css('margin-bottom', higherSlideHeight);
      $slide.find('.container').css({
        position: 'absolute',
        bottom: -$slide.find('.tcl-main-carousel__content').outerHeight(),
      });
    });
  }

  _moveDots($instanceEl, higherSlideHeight) {
    $instanceEl.find('.slick-dots').css('bottom', higherSlideHeight + 60);
  }

  _revertMobileAdjustments($instanceEl) {
    $instanceEl.find(`.${BEM.ELEMENT_SLIDE}`).each(function () {
      const $slide = $(this);

      // revert _setHeightToAllSlide()
      $slide.css({ 'margin-bottom': '' });
      $slide.find('.container').css({ position: '', bottom: '' });
    });

    // revert _moveDots()
    $instanceEl.find('.slick-dots').css('bottom', '');
  }

  _initSingleInstance($instanceEl) {
    const self = this;

    enquire.register(`${Responsive.getBreakpointMinMediaQuery('xs')} and ${Responsive.getBreakpointMaxMediaQuery('xs')}`, () => {
      self._initSlider($instanceEl);
      self._applyMobileAdjustments($instanceEl);
    });

    enquire.register(`${Responsive.getBreakpointMinMediaQuery('sm')}`, () => {
      self._initSlider($instanceEl);
      self._revertMobileAdjustments($instanceEl);
    });

    if (Responsive.getCurrentBreakpointName() === 'xs') {
      self._initSlider($instanceEl);
      self._applyMobileAdjustments($instanceEl);
    }
  }

  // init (or reinit if already initialized) the slider in case the instance contains more than one slide
  _initSlider($instanceEl) {
    const self = this;
    const $sliderItems = $instanceEl.find(`.${BEM.ELEMENT_SLIDER_ITEM}`);
    const autoplaySlider = $instanceEl.data('autoplay');

    if ($sliderItems.length > 1) {
      $instanceEl.find(`.${BEM.ELEMENT_SLIDER}`).filter('.slick-initialized').slick('unslick');
      $instanceEl.find(`.${BEM.ELEMENT_SLIDER}`).slick({ ...SliderConfig, autoplay: autoplaySlider });
    }

    $instanceEl.find(`.${BEM.ELEMENT_SLIDE}`).each(function () {
      const $slide = $(this);
      self._initSlideAnchorOverlay($slide);
    });
  }

  // if there is only one cta, make the whole slide clickable
  _initSlideAnchorOverlay($slide) {
    const $cta = $slide.find(`.${BEM.ELEMENT_FOOT} .btn`);
    if ($cta.length === 1) {
      const $anchorOverlay = $cta.clone(true);
      $anchorOverlay.addClass('tcl-main-carousel__anchor-overlay');
      $anchorOverlay.hover(() => $cta.addClass('active'), () => $cta.removeClass('active'));
      $slide.append($anchorOverlay);
    }
  }
}
