import Storage from './storage';

const storageKey = 'tclDebug';
const storage = new Storage();
const consoleLog = (message, bg = 'green', color = 'white') => {
  if (storage.fetch(storageKey)) {
    console.info(`%c ${message} `, `background: ${bg}; color: ${color};`); // eslint-disable-line no-console
  }
};

/**
 * version    1.0
 * class      <type> (Logger)
 * classdesc  Logger class to generate colourful logs
 *
 * Set 'tclDebug' in session storage to see logs in the browser console.
 *
 * eg.:
 * sessionStorage.setItem('tclDebug',true);
 */
export class Logger {
  /** Prints a green/white log in console
   * @param {String} message
   */
  static info(message) {
    consoleLog(message, 'green');
  }

  /** Prints a blue/white log in console
   * @param {String} message
   */
  static debug(message) {
    consoleLog(message, '#0E0E83');
  }

  /** Prints a red/white log in console
   * @param {String} message
   */
  static error(message) {
    consoleLog(message, 'red');
  }

  /** Prints a yellow/black log in console
   * @param {String} message
   */
  static log(message) {
    consoleLog(message, '#FFFF00', 'black');
  }

  /** Prints an orange/black log in console
   * @param {String} message
   */
  static warn(message) {
    consoleLog(message, '#FFA500', 'black');
  }
}
