import './item-list.scss';

import '../libs/jquery.matchHeight';
import PubSub from 'pubsub-js';
import enquire from 'enquire.js';
import { PageEvents } from './page';
import { Responsive } from '../utils';

const COMPONENT_NAME = 'item-list';

const BEM = {
  BLOCK: COMPONENT_NAME,
  MODIFIER_SLIDER_MOB: 'item-list--slider-mob',
  ELEMENT_CONTENT: 'item-list__content',
};

/**
 * version    1.0
 * class      <type> (ItemList)
 * classdesc
 */
export class ItemList {
  constructor($container = $('body')) {
    const self = this;

    // find elements
    this._els = {};
    this._els.container = $container;
    this._els.instances = this._els.container.find(`.${BEM.BLOCK}`);

    // init UI
    PubSub.subscribe(PageEvents.PAGE_READY, () => {
      this._els.instances.each(function () {
        self._initSingleInstance($(this));
      });
    });
  }

  // private methods

  _initSingleInstance($instanceEl) {
    this._initMatchHeight($instanceEl);
    this._initMobileSlider($instanceEl);
  }

  _initMatchHeight($instanceEl) {
    $instanceEl.find('.preview__title').matchHeight();
    $instanceEl.find('.preview__desc').matchHeight();
  }

  _initMobileSlider($instanceEl) {
    const self = this;
    if ($instanceEl.is(`.${BEM.MODIFIER_SLIDER_MOB}`)) {
      const $content = $instanceEl.find(`.${BEM.ELEMENT_CONTENT}`);

      enquire.register(`${Responsive.getBreakpointMinMediaQuery('xs')} and ${Responsive.getBreakpointMaxMediaQuery('sm')}`, () => {
        self._initMobileSliderInt($content);
      });

      enquire.register(`${Responsive.getBreakpointMinMediaQuery('md')}`, () => {
        $content.filter('.slick-initialized').slick('unslick');
      });

      const bp = Responsive.getCurrentBreakpointName();
      if (bp === 'xs' || bp === 'sm') {
        self._initMobileSliderInt($content);
      }
    }
  }

  _initMobileSliderInt($slider) {
    $slider.not('.slick-initialized').slick({
      infinite: false,
      variableWidth: true,
      // centerMode: true,
      // centerPadding: '32px',
    });
  }
}
