import { Cookies } from '../utils';
import './sondaggio.scss';

const COMPONENT_NAME = 'sondaggio';

const BEM = {
  BLOCK: COMPONENT_NAME,
};

/**
 * version    1.0
 * class      <type> (Sondaggio)
 * classdesc
 */
export class Sondaggio {
  constructor() {
    this._findUIElements();
    this._checkSondaggioCookie();
  }

  // private methods

  _findUIElements() {
    this._els = {
      sondaggio: $(`.${BEM.BLOCK}`),
    };
  }

  _checkSondaggioCookie() {
    const self = this;
    const idSondaggioCookie = self._els.sondaggio.data('sondaggio-cookie');
    const cookieSondaggio = `voted-survey-${idSondaggioCookie}`;

    if (Cookies.get(cookieSondaggio)) {
      self._els.sondaggio.find('.sondaggio__foot .btn:not([disabled])').addClass('btn-hidden');
      self._els.sondaggio.find('.sondaggio__foot .btn[disabled]').removeClass('btn-hidden');
    }
  }
}
