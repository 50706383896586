import './sharer.scss';

import { Sharer as SharerUtil } from '../utils/sharer';

const COMPONENT_NAME = 'tcl-sharer';
const BEM = {
  BLOCK: COMPONENT_NAME,
};

/**
 * version    1.0
 * class      <type> (Sharer)
 * classdesc  Social sharer
 *  *
 * Gestione degli elementi .tcl-sharer
 *
 * Attributi opzionali per .tcl-sharer:
 * - data-title
 * - data-url (url assoluto comprensivo di host)
 * - data-fragment (compresnsivo del caratter hash)
 * - data-image (url assoluto comprensivo di host)
 * - data-description
 *
 * Se questi non sono presenti verranno usati i meta tag 'og:' definiti nell'head della pagina.
 * Se nemmeno questi sono presenti, sono previsiti i seguenti fallback:
 * - title = document.title (indicato nell'head della pagina)
 * - url = location.href (url corrente dell'utente)
 *
 */
export class Sharer {
  constructor($container = $('body')) {
    const self = this;
    this._els = {
      sharer: $container.find(`.${BEM.BLOCK}`),
    };

    this._els.sharer.find('[data-share-social]').on('click', (e) => {
      self._onSocialClick(e);
    });
  }

  // private methods

  _onSocialClick(e) {
    const $t = $(e.currentTarget);
    if (e.type === 'click' || (e.type === 'keydown' && e.keyCode === 13)) {
      e.preventDefault();
      const $sharer = $t.closest('.tcl-sharer');
      const socialName = $t.attr('data-share-social');

      // carico le info dai tag meta "og:..."
      let ogTitle = $('meta[property="og:title"]').attr('content');
      let ogUrl = $('meta[property="og:url"]').attr('content');
      let ogImage = $('meta[property="og:image"]').attr('content');
      let ogDescription = $('meta[property="og:description"]').attr('content');

      // do la priorità a valori data-share-... sullo .tcl-sharebox, se specificati
      const attrTitle = $sharer.attr('data-share-title');
      const attrUrl = $sharer.attr('data-share-url');
      const attrFragment = $sharer.attr('data-share-fragment');
      const attrImage = $sharer.attr('data-share-image');
      const attrDescription = $sharer.attr('data-share-description');

      if (typeof attrTitle !== typeof undefined && attrTitle !== false) {
        ogTitle = attrTitle;
      }
      if (typeof attrUrl !== typeof undefined && attrUrl !== false) {
        ogUrl = attrUrl;
      }
      if (typeof attrImage !== typeof undefined && attrImage !== false) {
        ogImage = attrImage;
      }
      if (typeof attrDescription !== typeof undefined && attrDescription !== false) {
        ogDescription = attrDescription;
      }

      // fallback
      if (!ogTitle || ogTitle === '') {
        ogTitle = document.title;
      }
      if (!ogUrl || ogUrl === '') {
        ogUrl = window.location.href;
      }
      if (!ogImage) {
        ogImage = '';
      }
      if (!ogDescription) {
        ogDescription = '';
      }

      // se specificato (es: data-fragment="#look10" su .sharer)
      if (typeof attrFragment !== typeof undefined && attrFragment !== false) {
        const url = new URL(ogUrl);
        url.fragment = attrFragment;
        ogUrl = url.toString();
      }

      switch (socialName) { // eslint-disable-line default-case
        case 'facebook':
          SharerUtil.shareOnFacebook(ogUrl, ogTitle, ogImage, ogDescription);
          break;
        case 'twitter':
          SharerUtil.shareOnTwitter(ogUrl, ogTitle);
          break;
        case 'email':
          SharerUtil.shareOnEmail(ogUrl, ogTitle, ogImage, ogDescription);
          break;
        case 'linkedin':
          SharerUtil.shareOnLinkedin(ogUrl, ogTitle);
          break;
      }
    }
  }
}
