import './modal-search.scss';

const BEM = {
  BODY_MODAL_OPEN: 'modal-open--search',
};

/**
 * version    1.0
 * class      <type> (ModalSearch)
 * classdesc  Modale di ricerca che si apre al click sulla lente di ingrandimento dall'header
 */
export class ModalSearch {
  constructor() {
    const $body = $('body');
    const $modal = $('#modalSearch');

    $modal.on('show.bs.modal', () => {
      $body.addClass(BEM.BODY_MODAL_OPEN);
    });

    $modal.on('hidden.bs.modal', () => {
      $body.removeClass(BEM.BODY_MODAL_OPEN);
    });

    $modal.on('shown.bs.modal', () => {
      const self = this;

      self._checkEmptySearch();

      setTimeout(() => {
        $('#modalSearchInput').trigger('focus');
      }, 300);
    });
  }

  _checkEmptySearch() {
    $('.modal-search__form').on('submit', (event) => {
      const inputVal = $('.modal-search__input').val();
      if (inputVal.replace(/\s/g, '').length === 0) {
        event.preventDefault();
      }
    });
  }
}
