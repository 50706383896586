/** This class contains methods used to share content on different social networks.
 *
 * @class
 * @name Sharer
 */
export class Sharer {
  // private methods

  static _urlEncodeBase(n) {
    return encodeURI(n).replace(/#/g, '%23').replace(/:/g, '%3a').replace(/\//g, '%2f');
  }

  static _getLinkFallback(pageLink) {
    return !pageLink ? window.location.href : pageLink;
  }

  static _getTitleFallback(pageTitle) {
    return !pageTitle ? document.title : pageTitle;
  }

  static _fixUrl(url) {
    // se necessario aggiunge il protocollo e l'host all'url dell'immagine usando l'url corrente
    if (URL && typeof url !== 'undefined' && url !== '') {
      const imageURI = new URL(url);
      const currentURI = new URL(window.location.href);
      if (imageURI.username === '') {
        imageURI.username = currentURI.username;
      }
      if (imageURI.password === '') {
        imageURI.password = currentURI.password;
      }
      if (imageURI.protocolo === '') {
        imageURI.protocol = currentURI.protocol;
      }
      return imageURI.toString();
    }
    return '';
  }

  // public methods

  static shareOnFacebook(link, title, image, description) {
    // if (document.domain === '127.0.0.1') {
    //   alert('Per utilizzare la funzione di share facebook � necessario accedere al sito tramite http://localhost:8080 invece che http://127.0.0.1:8080');
    // }

    const sLink = Sharer._getLinkFallback(link);
    const sTitle = title; // eslint-disable-line no-unused-vars
    const sImage = Sharer._fixUrl(image); // eslint-disable-line no-unused-vars
    const sDescription = description; // eslint-disable-line no-unused-vars

    // if (typeof sTitle !== 'undefined' && typeof sImage !== 'undefined' && sTitle !== '' && sImage !== '') {
    //   //Metodo di condivisione Ibrido tra Share dialog e Open Graph share
    //   //per fare share passando immagine e testi come parametri invece che dai tag opengraph dell'head della pagina
    //   //metodo da: https://www.grobmeier.de/sharing-images-facebook-javascript-19102015.html
    //   //documentazione (incompleta): https://developers.facebook.com/docs/sharing/reference/share-dialog
    //   //- Necessita di appId
    //   //- Prende le informazioni dai tag open graph della pagina oppure permette di passare immagine e descrizione per un singolo elemento della pagina
    //   //- Permette all'utente di scegliere dove pubblicare (es: proprie news, messaggio privato, pagina gestita)
    //   const ogObj = {
    //     'og:url': sLink,
    //     'og:title': sTitle,
    //     'og:image': sImage
    //     //'og:description': sDescription,
    //     //'og:og:image:width': imageWidth,
    //     //'og:image:height': imageHeight,
    //   };
    //   FB.ui({
    //     method: 'share_open_graph',
    //     action_type: 'og.shares',
    //     action_properties: JSON.stringify({
    //       object: ogObj
    //     })
    //   });

    //   ////Metodo usato in style master (https://parfums.ferragamo.com/it/ita/style-master/style-master-detail)
    //   ////per fare share passando immagine e testi come parametri invece che dai tag opengraph dell'head della pagina
    //   //FB.ui({
    //   //    method: 'share',
    //   //    name: 'Facebook Dialogs',
    //   //    href: sLink,
    //   //    link: 'https://developers.facebook.com/docs/dialogs/',
    //   //    title: sTitle,
    //   //    picture: sImage,
    //   //    caption: '',
    //   //    description: sDescription
    //   //},
    //   //(response) {
    //   //    if (response && response.post_id) {
    //   //        console.log(sTitle + ' SHARED ON FACEBOOK!');
    //   //    } else {
    //   //        console.log('Error While Sharing!')
    //   //    }
    //   //});

    // } else {

    // if (document.domain === 'localhost') {
    //   alert('La funzione di Share dialog non funziona in locale e in ambienti non pubblici (la pagina deve essere raggiungibile da facebook)');
    // }

    // //Metodo di condivisione Share dialog
    // //https://developers.facebook.com/docs/sharing/reference/share-dialog
    // //Prende le informazioni dai tag opengraph della pagina indicata
    // FB.ui({
    //   method: 'share',
    //   href: sLink,
    // }, (response) {});

    // Metodo di condivisione "Share button" (https://developers.facebook.com/docs/plugins/share-button)
    // - Non necessita di appId
    // - Prende le informazioni dai tag open graph della pagina (NON � possibile passare immagine e descrizione per un singolo elemento della pagina)
    // - Permette all'utente di scegliere dove pubblicare (es: proprie news, messaggio privato, pagina gestita)
    const URL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sLink)}`;
    window.open(URL, 'newWindow', 'width=626, height=436');

    // }
  }

  static shareOnTwitter(link, title) { // , hashTag, via) {
    const _link = Sharer._getLinkFallback(link);
    const URL = `https://twitter.com/share?url=${Sharer._urlEncodeBase(_link)}&text=${Sharer._urlEncodeBase(title)}`; // &hashtags=${encodeURIComponent(hashTag)}&via=${encodeURIComponent(via)}`;
    window.open(URL, 'newWindow', 'width=626, height=436');
  }

  static shareOnEmail(link, title) { // , image, description) {
    const _link = Sharer._getLinkFallback(link);
    const _title = Sharer._getTitleFallback(title);

    // // apertura overlay con form per invio email
    // $(document).emailSharePopup('open', {
    //   link: _link,
    //   title: _title,
    //   image,
    //   description,
    // });

    // apertura link con semplice mailto:
    window.open(`mailto:?subject=${encodeURIComponent(_title)}&body=${encodeURIComponent(_link)}`, '_blank');
  }

  static shareOnLinkedin(link, title) {
    const _link = Sharer._getLinkFallback(link);
    const _title = Sharer._getTitleFallback(title);
    const URL = `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(_link)}&title=${encodeURIComponent(_title)}`;
    window.open(URL, 'newWindow', 'width=626, height=436');
  }
}
