import './search-heading.scss';

const COMPONENT_NAME = 'tcl-search-heading';
// const EVENT_KEY = COMPONENT_NAME;
const BEM = {
  BLOCK: COMPONENT_NAME,
};

/**
 * version    1.0
 * class      <type> (SearchHeading)
 * classdesc  Search Heading: search form with occhiello, input, button with search icon
 */
export class SearchHeading {
  constructor() {
    this._findUIElements();
    this._bindEvents();
  }

  // private methods

  _findUIElements() {
    this._els = {
      searchHeading: $(`.${BEM.BLOCK}`),
    };
  }

  _bindEvents() {
    const self = this;

    self.initSearchHeading();
  }

  initSearchHeading() {
    const self = this;
    const inputSearch = self._els.searchHeading.find('.tcl-search-heading__input').val();

    $('.preview--layout-search .preview__title, .preview--layout-search .preview__desc').each(function () {
      const reg = new RegExp(inputSearch, 'gi');
      $(this).html($(this).text().replace(reg, `<span class="mark">${inputSearch}</span>`));
    });
  }
}
