let instance = null;

/** Wrapper class around sessionStorage.
 *
 * @class
 * @name Storage
 */
export default class Storage {
  /**
   * Storage class constructor
   *
   * @example
   * import { Storage } from './storage';
   * const storage = new Storage();
   * storage.save('storage-key', true);
   * ...
   * storage.fetch('storage-key');
   * // true
   *
   */
  constructor() {
    if (window.browserLocalObject === undefined) {
      window.browserLocalObject = {};
    }
    if (!instance) {
      instance = this;
    }
    this.storageType = window.sessionStorage;
    this.stringifyCheck = false;
    return instance;
  }

  /** Tries to transform Session data parameter to string.
   * @param {object} data
   * @returns {Object|String}
   */
  stringify(data) {
    if (this.stringifyCheck) {
      return JSON.stringify(data);
    }
    return data;
  }

  /** Tries to transofrm the JSON parameter to an object.
   * @param {String} str
   * @returns {Object|String}
   */
  static parse(str) {
    try {
      return JSON.parse(str); // number or object
    } catch (e) {
      return str; // string
    }
  }

  /** Checks whether storage is supported or not.
   * @returns {Boolean}
   */
  isBrowserStorageSupported() {
    try {
      this.storageType.setItem('testStorage', '1');
      this.storageType.removeItem('testStorage');
      return true;
    } catch (error) {
      return false;
    }
  }

  /** Guess whether data is an object or not.
   * @param {Object} data
   */
  findDataType(data) {
    if (typeof data === 'object') {
      this.stringifyCheck = true;
    } else {
      this.stringifyCheck = false;
    }
  }

  /** Saves specific 'data' to specific 'key' in the storage
   *
   * @param {String} key - storage's key to save
   * @param {Object} data - value to store
   */
  save(key, data) {
    this.findDataType(data);
    if (this.isBrowserStorageSupported()) {
      this.storageType.setItem(key, this.stringify(data));
    } else {
      window.browserLocalObject[key] = data;
    }
  }

  /** Fetches a 'key' attribute from the storage.
   * @param {String} key - storage's key to get
   * @returns {Object}
   */
  fetch(key) {
    if (this.isBrowserStorageSupported()) {
      return Storage.parse(this.storageType.getItem(key));
    }
    return window.browserLocalObject[key];
  }

  /** Fetches all attributest stored.
   * @returns {Object}
   */
  fetchAll() {
    if (this.isBrowserStorageSupported()) {
      return this.storageType;
    }
    return window.browserLocalObject;
  }

  /** Removes a value from the storage.
   *  @param {String} key - storage's key to remove
   */
  remove(key) {
    if (this.isBrowserStorageSupported()) {
      this.storageType.removeItem(key);
    } else {
      delete window.browserLocalObject[key];
    }
  }

  /** Removes all the values from the storage. */
  removeAll() {
    if (this.isBrowserStorageSupported()) {
      this.storageType.clear();
    } else {
      window.browserLocalObject = {};
    }
  }
}
